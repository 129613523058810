import React from "react";
import { View, StyleSheet } from "react-native";
import routes from "../../navigation/routes";
import NavigationBar from "../components/NavigationBar";
import Logger from "../../common/utils/Logger";
import AuthNavigator from "../../navigation/AuthNavigator";
import RootController from "./RootController";
import { metamaskSignIn } from "../../api/metamask";
import { showToast } from "../../common/utils/ControllerUtils";
import nftclient from "../../api/nftclient";

class RootAuthController extends RootController {
  state = {
    currentController: null,
    isMobile: false,
  };

  navItems = [
    {
      route: routes.browse,
      name: "Explore",
      icon: "compass",
    },
    {
      route: routes.marketing,
      name: "Marketing",
      icon: "cellphone",
    },
  ];

  componentDidMount() {
    super.componentDidMount();
  }

  handleLogout = () => {
    Logger("Signing out");
    this.context.updateUser(null);
  };

  handleLogin = async () => {
    this.setState({ loginIn: true });
    const response = await metamaskSignIn(this.onSignInError);

    Logger("Metamask Login: ", response);

    if (response && response.address) {
      const loginResponse = await login(response.address, response.signature);
      if (loginResponse.ok) {
        Logger("API /user/login: ", loginResponse);

        //if success... will set the new bearer token.....
        nftclient.setHeader(
          "Authorization",
          "Bearer " + loginResponse.data.access_token
        );

        this.context.updateUser({
          ...this.context.user,
          jwt: loginResponse.data.access_token,
          address: response.address,
          authenticated: true,
        });
      } else {
        this.onSignInError("Unable to process request");
      }
    }
  };

  onSignInError = (error) => {
    showToast("Oops...", error);
  };

  render() {
    return (
      <View style={styles.container}>
        {this.showNavigation() && (
          <NavigationBar
            navItems={this.navItems}
            currentController={this.state.currentController}
            onLogin={() => {
              Logger("User clicked on login");
              this.handleLogin();
            }}
          />
        )}

        <AuthNavigator
          onControllerChanged={(controller) => {
            Logger("Current controller: ", controller);
            this.setState({ currentController: controller });
          }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 0,
    flex: 1,
  },
});

export default RootAuthController;
