export const linking = {
  config: {
    screens: {
      marketing: "",
      home: "home",
      browse: "/browse",
      detail: "/detail/:id",
      createItem: "/new",
      profile: "/profile/:id?",
      notifications: "/notifications",
      privacy: "/privacy",
      terms: "/terms",
      cookies: "/cookies",
      notfound: "*",
    },
  },
};

const routes = {
  marketing: "marketing",
  home: "home",
  browse: "browse",
  browsedetail: "detail",
  createItem: "new",
  profile: "profile",
  notifications: "notifications",
  privacy: "privacy",
  terms: "terms",
  cookies: "cookies",
  notFound: "notfound",
};

export default routes;
