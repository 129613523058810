import React from "react";
import { View, StyleSheet, Text } from "react-native";
import colors from "../../config/colors";
import BaseController from "../../common/base/BaseController";

class HomeController extends BaseController {
  state = {};
  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.title}>Home</Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    padding: 20,
  },
  title: {
    fontSize: 28,
  },
});

export default HomeController;
