import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtCard from "../../components/NtCard";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import Logger from "../../common/utils/Logger";

function NtNFTItem({ item, onPress }) {
  return (
    <NtCard style={styles.container} onPress={onPress}>
      {item.asset && (
        <Image
          style={{ width: "100%", height: 200 }}
          source={{ uri: item.asset }}
        />
      )}
      <View style={{ padding: 10 }}>
        <NtText style={styles.title}>{item.name}</NtText>
        <NtText style={styles.author}>By: {item.created_by}</NtText>
        <NtText style={styles.price}>Price: {item.price}</NtText>
      </View>
    </NtCard>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 200,
    padding: 0,
    overflow: "hidden",
  },
  title: {
    color: colors.darkest,
    fontSize: 16,
    fontWeight: "600",
  },
  author: {
    color: colors.darkest,
    fontSize: 14,
    fontWeight: "500",
  },
  price: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
});

export default NtNFTItem;
