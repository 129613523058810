//while on development... please start chrome with
//open -n -a /Applications/Google\ Chrome.app/Contents/MacOS/Google\ Chrome --args --user-data-dir="/tmp/chrome_dev_test" --disable-web-security

import React from "react";
import {
  Animated,
  CheckBox,
  View,
  StyleSheet,
  Image,
  Text,
  ActivityIndicator,
} from "react-native";
import NtButton from "../components/NtButton";
import NtTextInput from "../components/NtTextInput";
import colors from "../config/colors";
import { subscribe } from "../api/newsletter";
import Toast from "react-native-toast-message";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

class MarketingController extends React.Component {
  state = {
    checkboxSelected: true,
    fadeAnim: new Animated.Value(0),
    email: "",
    isLoading: false,
  };

  fadeIn = () => {
    // Will change fadeAnim value to 1 in 5 seconds
    Animated.timing(this.state.fadeAnim, {
      toValue: 1,
      duration: 5000,
    }).start();
  };

  validate = (text) => {
    console.log(text);
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    return reg.test(text) === true;
  };

  showToast = (title, message, type = "success") => {
    Toast.show({
      type: type,
      text1: title,
      text2: message,
    });
  };

  handleSubmit = async () => {
    if (!this.validate(this.state.email)) {
      this.showToast("Oops...", "Please enter a valid email address", "error");
      return;
    }

    this.setState({ error: null });
    this.setState({ isLoading: true });
    const result = await subscribe(this.state.email);
    this.setState({ isLoading: false });

    if (result.ok) {
      this.setState({ email: "" });
      this.showToast("Success", "Successfully uploaded your email");
    } else {
      this.showToast(
        "Oops...",
        "Unable to upload your email. Please try again.",
        "error"
      );
    }
  };

  componentDidMount() {
    this.fadeIn();
  }

  render() {
    return (
      <View style={styles.container}>
        {/* Top view */}
        <View
          style={{
            alignItems: "center",
            width: "100%",
            marginTop: 120,
            justifyContent: "center",
          }}
        >
          <Animated.View
            style={[
              styles.fadingContainer,
              {
                // Bind opacity to animated value
                opacity: this.state.fadeAnim,
              },
            ]}
          >
            <View
              style={{
                alignItems: "flex-end",
              }}
            >
              <Image
                style={styles.image}
                source={require("../assets/images/vinzini-big.png")}
              />
              <MaterialCommunityIcons
                style={{ position: "absolute", marginTop: 143 }}
                name={"trademark"}
                size={15}
                color={"#8932B8"}
              />
            </View>

            <Text style={styles.tagline}>
              Fuel for the Digital Economy. During a Digital Renaissance.
            </Text>
          </Animated.View>
        </View>

        {/* middle view */}

        <View
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            marginTop: -100,
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <NtTextInput
              containerStyle={{ backgroundColor: "white", width: 420 }}
              placeholder="Join the waitlist for early access"
              textColor={colors.darker}
              value={this.state.email}
              onChangeText={(text) => {
                this.setState({ email: text });
              }}
            />

            <NtButton
              containerStyle={{ width: 100, marginLeft: 20 }}
              text={"Submit"}
              onPress={() => {
                this.handleSubmit();
              }}
              isLoading={this.state.isLoading}
            />
          </View>
          <View style={styles.checkboxContainer}>
            <CheckBox
              value={this.state.checkboxSelected}
              onValueChange={() => {
                this.setState({
                  checkboxSelected: !this.state.checkboxSelected,
                });
              }}
              style={styles.checkbox}
              color={colors.darker}
            />
            <Text style={styles.checkboxText}>
              Yes, please send me the latest news about the VINZINI NFT
              marketplace
            </Text>
          </View>
        </View>

        {/* <View style={{ flex: 1 }} /> */}

        {/* footer view */}
        <View style={{ width: "100%", alignItems: "center" }}>
          <Text style={styles.footerText}>
            COPYRIGHT (C) 2021 VINZINI DIGITAL LLC - ALL RIGHTS RESERVED
          </Text>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "black",
  },
  fadingContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 200,
    height: 200,
    resizeMode: "contain",
    marginLeft: 15,
    marginRight: 15,
  },
  tagline: {
    color: "#8932B8",
    fontSize: 18,
    fontWeight: "300",
    marginBottom: 60,
  },
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  checkboxText: {
    color: colors.teal,
    fontSize: 16,
    fontWeight: "300",
    marginLeft: 10,
  },
  checkbox: {
    tintColor: "yellow",
  },
  footerText: {
    color: colors.teal,
    fontSize: 16,
    fontWeight: "300",
    marginBottom: 60,
  },
});

export default MarketingController;
