const settings = {
  //this is using proxy server
  // apiURL: "http://199.193.189.116",
  apiURL: "https://api.mds.nettalk.com",
  nftAPIURL: "https://vinzini.ntlk.co",

  pageSizeLoad: 25,
  mobileSize: 800,
};

export default settings;
