import React, { useContext } from "react";
import { View, StyleSheet, Image } from "react-native";
import { TouchableOpacity } from "react-native-web";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import routes from "../../navigation/routes";
import NtNavigationButton from "./NtNavigationButton";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import UserContext from "../../context/UserContext";
import Logger from "../../common/utils/Logger";
import { useMobile } from "../../hooks/useMobile";
import { useNavigation } from "@react-navigation/native";

function NavigationBar({ navItems, currentController, onLogin, onLogout }) {
  const userContext = useContext(UserContext);
  const nav = useNavigation();
  const isMobile = useMobile();

  const renderNavItem = (item) => {
    return (
      <View key={item.name} style={{ flexDirection: "row" }}>
        <TouchableOpacity onPress={() => nav.navigate(item.route)}>
          <NtNavigationButton
            isMobile={isMobile}
            title={item.name}
            icon={item.icon}
            selected={currentController === item.route}
          />
        </TouchableOpacity>

        <View style={{ width: 30 }} />
      </View>
    );
  };

  const isUserAuthenticated = () => {
    return userContext.user && userContext.user.authenticated;
  };

  const renderLoginButton = () => {
    return (
      <TouchableOpacity
        onPress={() => {
          if (isUserAuthenticated()) {
            if (onLogout) {
              onLogout();
            }
          } else {
            if (onLogin) {
              onLogin();
            }
          }
        }}
        style={{ marginRight: 10, flexDirection: "row" }}
      >
        <MaterialCommunityIcons
          name={isUserAuthenticated() ? "logout" : "login"}
          color={colors.lighter}
          size={20}
        />
        <NtText style={styles.logout}>
          {isUserAuthenticated() ? "Logout" : "Login"}
        </NtText>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        source={require("../../assets/images/vinzini.png")}
      />
      <View style={[styles.innerContainer, { marginLeft: 30 }]}>
        <View style={{ flexDirection: "row" }}>
          {navItems && navItems.map((each) => renderNavItem(each))}
        </View>
        <View style={{ flex: 1 }} />
        {renderLoginButton()}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 80,
    padding: 10,
    backgroundColor: colors.darkest,
    elevation: 4,
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    height: 40,
    width: 150,
    resizeMode: "contain",
  },
  innerContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logout: {
    color: colors.lighter,
    fontWeight: "600",
    fontSize: 16,
    marginLeft: 8,
  },
});

export default NavigationBar;
