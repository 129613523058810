import nftclient from "./nftclient";

const endpoint = "/api/user/login";
export const login = (address, signature) => {
  return nftclient.post(endpoint, { address: address, signature: signature });
};

export const setAuthtication = (user) => {
  if (user && user.jwt) {
    nftclient.setHeader("Authorization", "Bearer " + user.jwt);
  }
};

// export const setUser = (user) => {
//   return nftclient.put(/user/me)
// }

export const fetchUser = () => {
  return nftclient.get("/api/user/me/");
};
