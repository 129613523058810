import Toast from "react-native-toast-message";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const showToast = (title, message, type = "success") => {
  Toast.show({
    type: type,
    text1: title,
    text2: message,
  });
};



export const asyncStorageSetItem = async (key, value) => {
  try {
    return await AsyncStorage.setItem(key, value);
  } catch (e) {
    Logger("Unable to save the value to async storage", e);
  }
};

export const asyncStorageGetItem = async (key) => {
  try {
    return await AsyncStorage.getItem(key);
  } catch (e) {
    Logger("Unable to retrieve the item from async storage", e);
  }
};

export const asyncStorageSetData = async (key, value) => {
  try {
    const jsonValue = JSON.stringify(value);
    return await AsyncStorage.setItem(key, jsonValue);
  } catch (e) {
    Logger("Unable to save the value to async storage", e);
  }
};

export const asyncStorageGetData = async (key) => {
  try {
    return await AsyncStorage.getItem(key);
  } catch (e) {
    Logger("Unable to retrieve the stored data", e);
  }
};
