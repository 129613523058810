import client from "./client";

const url = "/newsletter/subscription";

export const subscribe = (email) => {
  const data = new FormData();
  data.append("email", email);
  data.append("system", "nft");
  data.append("service", "early-access");

  return client.post(url, data);
};
