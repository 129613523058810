export default {
  darkest: "#323B5B",
  darker: "#5A6795",
  dark: "#808CBA",
  teal: "#BDC5E3",
  lighter: "#D5E0ED",
  lightest: "#E7ECF2",
  white: "#ffffff",
  blue: "#0d58a3",

  //objectivity
  objectivityFill: "#0d58a3",
  objectivityUnfill: "#bcd1e5",

  //sentiment - negative
  sentimentNegativeFill: "#ab1415",
  sentimentNegativeUnfill: "#ebb3b3",

  //sentiment - positive
  sentimentPositiveFill: "#55bc24",
  sentimentPositiveUnfill: "#bbfa9d",

  //sentiment - neutral
  sentimentNeutralFill: "#d0dae6",
  sentimentNeutralUnfill: "#d0dae6",

  //navigation
  navigationColor: "#323B5B",
  navigationTintColor: "#ffffff",
};
