import React from "react";
import { View, StyleSheet } from "react-native";
import AppNavigator from "../../navigation/AppNavigator";
import routes from "../../navigation/routes";
import NavigationBar from "../components/NavigationBar";
import Logger from "../../common/utils/Logger";
import RootController from "./RootController";

class RootAppController extends RootController {
  state = {
    currentController: null,
    isMobile: false,
  };

  navItems = [
    {
      route: routes.browse,
      name: "Explore",
      icon: "compass",
    },
    {
      route: routes.createItem,
      name: "New NFT",
      icon: "pencil-plus",
    },
    {
      route: routes.profile,
      name: "Profile",
      icon: "account-circle-outline",
    },
    {
      route: routes.marketing,
      name: "Marketing",
      icon: "cellphone",
    },
  ];

  componentDidMount() {
    super.componentDidMount();
  }

  handleLogout = () => {
    console.log("Signing out");
    this.context.updateUser(null);
  };

  render() {
    return (
      <View style={styles.container}>
        {this.showNavigation() && (
          <NavigationBar
            navItems={this.navItems}
            currentController={this.state.currentController}
            onLogout={() => {
              Logger("User clicked on Logout");
              this.context.updateUser(null);
            }}
          />
        )}

        <AppNavigator
          onControllerChanged={(controller) => {
            Logger("Current controller: ", controller);
            this.setState({ currentController: controller });
          }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 0,
    flex: 1,
  },
});

export default RootAppController;
