import React from "react";
import { View, StyleSheet, TouchableOpacity, Text } from "react-native";
import colors from "../config/colors";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import NtActivityIndicator from "./NtActivityIndicator";

function NtButton({
  text,
  textColor = "white",
  icon,
  onPress,
  containerStyle,
  textStyle,
  isLoading = false,
}) {
  const handleClick = () => {
    if (isLoading) {
      console.log("clicking while loading");
    } else if (onPress) {
      onPress();
    }
  };

  return (
    <TouchableOpacity
      style={[styles.button, containerStyle]}
      onPress={handleClick}
    >
      {icon && (
        <MaterialCommunityIcons
          style={styles.icon}
          name={icon}
          size={20}
          color={textColor}
        />
      )}
      {text && (
        <Text style={[styles.text, { color: textColor }, textStyle]}>
          {text}
        </Text>
      )}

      {isLoading && (
        <NtActivityIndicator
          containerStyle={{ marginLeft: 10 }}
          size="small"
          color={textColor}
        />
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  button: {
    flexDirection: "row",
    backgroundColor: colors.darker,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    width: "100%",
  },
  text: {
    color: "white",
    fontSize: 14,
    textTransform: "capitalize",
    fontWeight: "400",
  },
});

export default NtButton;
