import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import colors from "../../config/colors";
import BaseController from "../../common/base/BaseController";
import NtFooterBar from "../../root/components/NtFooterBar";
import NtText from "../../components/NtText";
import { fetchUser } from "../../api/user";
import Logger from "../../common/utils/Logger";

class ProfileController extends BaseController {
  state = {
    user: null,
  };

  componentDidMount() {
    this.handleUserFetch();
  }

  handleUserFetch = async () => {
    const response = await fetchUser();
    if (response.ok) {
      Logger("--- Current user: ", response.data);
      this.setState({ user: response.data });
    }
  };

  render() {
    return (
      <View style={styles.container}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <View style={styles.contentContainer}>
            <NtText style={styles.title}>Profile</NtText>
            <NtText>{JSON.stringify(this.state.user)}</NtText>
          </View>
          <NtFooterBar />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightest,
  },
  contentContainer: {
    flex: 1,
    padding: 20,
  },
  title: {
    fontSize: 22,
    color: colors.darkest,
    fontWeight: "600",
  },
});

export default ProfileController;
