import React from "react";
import { View, StyleSheet } from "react-native";
import NtCard from "../../components/NtCard";
import NtText from "../../components/NtText";
import colors from "../../config/colors";

function NtCategoryItem({ name }) {
  return (
    <NtCard style={styles.container}>
      <View style={{ width: 150, height: 180 }}></View>
      <NtText style={styles.title}>{name}</NtText>
    </NtCard>
  );
}

const styles = StyleSheet.create({
  container: {},
  title: {
    alignSelf: "center",
    color: colors.darkest,
    fontSize: 16,
    fontWeight: "600",
  },
});

export default NtCategoryItem;
