import React from "react";
import { View, StyleSheet, Text, Image } from "react-native";
import NtButton from "../components/NtButton";
import NtTextInput from "../components/NtTextInput";
import colors from "../config/colors";
import Fontisto from "react-native-vector-icons/Fontisto";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { ScrollView } from "react-native-web";
import {
  asyncStorageGetItem,
  showToast,
  asyncStorageSetItem,
} from "../common/utils/ControllerUtils";
import { subscribe } from "../api/newsletter";
import NtFooterBar from "../root/components/NtFooterBar";
import BaseController from "../common/base/BaseController";

const KEY_SUCCESS_SUBMITTED = "key-success-submitted2";
class MarketingController2 extends BaseController {
  state = {
    email: "",
    isLoading: false,
    allowSubmit: false,
    isMobile: false,
  };

  componentDidMount() {
    this.checkIfSubmittable();

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize = () => {
    if (window.innerWidth <= 760 && !this.state.isMobile) {
      this.setState({ isMobile: true });
    } else if (window.innerWidth > 760 && this.state.isMobile) {
      this.setState({ isMobile: false });
    }
  };

  checkIfSubmittable = async () => {
    const successSubmitted = await asyncStorageGetItem(KEY_SUCCESS_SUBMITTED);
    if (successSubmitted) {
      this.setState({ allowSubmit: false });
    } else {
      this.setState({ allowSubmit: true });
    }
  };

  validate = (text) => {
    console.log(text);
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    return reg.test(text) === true;
  };

  handleSubmit = async () => {
    if (!this.validate(this.state.email)) {
      showToast("Oops...", "Please enter a valid email address", "error");
      return;
    }

    this.setState({ isLoading: true });
    const result = await subscribe(this.state.email);
    this.setState({ isLoading: false });

    if (result.ok) {
      this.setState({ email: "" });
      showToast("Success", "Successfully uploaded your email");
      asyncStorageSetItem(true, KEY_SUCCESS_SUBMITTED);
      this.setState({ allowSubmit: false });
    } else {
      showToast(
        "Oops...",
        "Unable to upload your email. Please try again.",
        "error"
      );
    }
  };

  render() {
    return (
      <View style={styles.container}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          {/* header */}
          <View style={styles.headerContainer}>
            <View style={{ flex: 0.2 }} />
            <Image
              style={styles.headerImage}
              source={require("../assets/images/vinzini-big.png")}
            />
          </View>

          {/* Main container */}
          <View style={styles.mainContainer}>
            <View style={{ flex: 0.2 }} />
            <View
              style={{
                flex: 1,
                flexDirection: this.state.isMobile ? "column" : "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* left */}
              <View
                style={{
                  flex: 1,
                  minWidth: 350,
                }}
              >
                <Text style={styles.mainTitle}>
                  For the People - Creators Traders & Collectors
                </Text>

                <Text style={styles.mainSubtitle}>
                  Fuel for the Digital Economy. During a Digital Renaissance
                </Text>

                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    marginTop: 20,
                    alignItems: "center",
                  }}
                >
                  <NtTextInput
                    containerStyle={{
                      backgroundColor: "white",
                      flex: 1,
                      height: 40,
                      maxWidth: 500,
                    }}
                    placeholder="Enter your email"
                    textColor={colors.darker}
                    value={this.state.email}
                    onChangeText={(text) => {
                      this.setState({ email: text });
                    }}
                  />

                  {this.state.allowSubmit && (
                    <NtButton
                      containerStyle={{
                        width: 120,
                        marginLeft: 20,
                        height: 40,
                      }}
                      text={"Sign Up"}
                      onPress={() => {
                        this.handleSubmit();
                      }}
                      isLoading={this.state.isLoading}
                    />
                  )}

                  {!this.state.allowSubmit && (
                    <View
                      style={{
                        flexDirection: "row",
                        marginLeft: 20,
                        alignItems: "center",
                      }}
                    >
                      <MaterialCommunityIcons
                        name={"check"}
                        size={20}
                        color={colors.lightest}
                      />
                      <Text style={styles.submitted}>Submitted!</Text>
                    </View>
                  )}
                </View>
              </View>

              <Image
                style={styles.mainImage}
                source={require("../assets/images/nft-cards-400x400.png")}
              />
            </View>
            <View style={{ flex: 0.2 }} />
          </View>

          {/* quote container */}
          <View style={styles.quoteContainer}>
            <View style={{ flex: 0.2 }} />
            <View style={{ flex: 1 }}>
              <Fontisto name={"quote-a-right"} size={35} color={"#4C6BDA"} />
              <Text style={styles.quoteText}>
                For hundreds of years, kings, queens, and noble folk decided
                which art was relevant. Those works they chose live on in
                importance today Then, for the first time, in the Victorian era
                import {MaterialCommunityIcons} from import BaseController from
                '../common/base/BaseController';
                'react-native-vector-icons/MaterialCommunityIcons'; of the
                1800s, galleries, museums, and collectors began to move markets.
                Fast forward to the late 1900's... By the time the public learns
                about Warhol or Basquiat, their work has become expensive, the
                best pieces gobbled up by known collectors and market makers...
                The general public left only with less desired works, prints and
                editions...unless of course they paid up handsomely. But around
                2018, a new paradigm began. In NFT, there are no museums. No
                galleries, other than the marketplaces, and the galleries made
                by collectors themselves. Any artist, from anywhere in the
                world, with no invitation, can mint a drop. So now, with NFTs,
                for the first time ever... YOU can decide which artists will
                define this generation. YOU can access their art before they get
                big YOU can make them big, by voting with your wallets. By
                showing them in your own galleries... NFT is seizing power from
                the institutions. We are the market makers now. We create our
                own museums. We decide which art defines this generation. We are
                the digital renaissance. And we are just getting started.
              </Text>
              <View style={{ flex: 1, alignItems: "flex-end", marginTop: 10 }}>
                <Fontisto name={"quote-a-left"} size={35} color={"#4C6BDA"} />
              </View>

              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <MaterialCommunityIcons
                  name={"twitter"}
                  size={40}
                  color={"#4F8DEC"}
                />

                <Text style={styles.quoteAuthor}>@CozomoMedici</Text>
              </View>
            </View>
            <View style={{ flex: 0.2 }} />
          </View>

          <NtFooterBar />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "black",
  },
  headerContainer: {
    width: "100%",
    backgroundColor: "#210F33",
    flexDirection: "row",
    paddingTop: 40,
  },
  headerImage: {
    width: 100,
    height: 100,
  },
  mainContainer: {
    width: "100%",
    flexDirection: "row",
    backgroundColor: "#210F33",
  },
  mainTitle: {
    color: "white",
    fontWeight: "bold",
    fontSize: 25,
  },
  mainSubtitle: {
    color: "white",
    marginTop: 6,
  },
  mainImage: {
    width: 400,
    height: 400,
  },
  quoteContainer: {
    backgroundColor: "#181028",
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: 80,
    paddingBottom: 80,
  },
  quoteImage: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  quoteText: {
    flex: 1,
    color: colors.lighter,
    marginTop: 10,
    fontStyle: "italic",
  },
  quoteAuthor: {
    color: colors.lightest,
    fontSize: 18,
    fontWeight: "600",
    marginLeft: 10,
  },
  bottomContainer: {
    width: "100%",
    backgroundColor: "#210F33",
    paddingTop: 20,
    paddingBottom: 20,
  },
  copyright: {
    color: "white",
    fontSize: 10,
    flex: 1,
    textAlign: "center",
  },
  bottomImage: {
    width: 60,
    height: 60,
  },
  powerByText: {
    color: "white",
    fontSize: 10,
  },
  powerByImage: {
    width: 60,
    height: 40,
    resizeMode: "contain",
  },
  submitted: {
    color: colors.lightest,
    fontSize: 16,
    marginLeft: 6,
  },
});

export default MarketingController2;
