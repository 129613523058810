import React from "react";
import { View, StyleSheet, Text, Image, ScrollView } from "react-native";
import BaseController from "../../common/base/BaseController";
import colors from "../../config/colors";
import * as ImagePicker from "expo-image-picker";
import NtButton from "../../components/NtButton";
import Logger from "../../common/utils/Logger";
import NtFooterBar from "../../root/components/NtFooterBar";

class CreateNftController extends BaseController {
  state = {
    image: null,
  };

  handleImagePick = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      presentationStyle:
        ImagePicker.UIImagePickerPresentationStyle.BlurOverFullScreen,
    });

    Logger("Here is the uploaded image: ", result);

    if (!result.cancelled) {
      this.setState({ image: result.uri });
    }
  };

  render() {
    return (
      <View style={styles.container}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <View style={styles.contentContainer}>
            <Text style={styles.title}>New Item</Text>
            <NtButton
              containerStyle={{ width: 200 }}
              text={"Select Image"}
              onPress={this.handleImagePick}
            />
            {this.state.image && (
              <Image
                source={{ uri: this.state.image }}
                style={{
                  width: 200,
                  height: 200,
                  marginTop: 20,
                  resizeMode: "contain",
                }}
              />
            )}
          </View>
          <NtFooterBar />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightest,
  },
  contentContainer: {
    flex: 1,
    padding: 20,
  },
  title: {
    fontSize: 22,
    color: colors.darkest,
    fontWeight: "600",
  },
});

export default CreateNftController;
