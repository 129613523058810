import { create } from "apisauce";
import settings from "../config/settings";

const client = create({
  baseURL: settings.apiURL,
  headers: { Accept: "application/json" },
});

client.addAsyncRequestTransform(async (request) => {
  request.headers["Content-Type"] = "application/json";
  request.headers["Accept"] = "application/json";
  request.headers["Access-Control-Allow-Origin"] = "*";
  request.headers["Authorization"] = "Basic  b3NjYXJhOnBhc3N3b3JkMUE=";
});

export default client;
