import { ethers } from "ethers";

export const metamaskSignIn = async (setError) => {
  try {
    if (!window.ethereum) {
      throw new Error("No crypto wallet found. Please install it.");
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const data = await window.ethereum.enable();
    console.log(data);
    var rightnow = (Date.now() / 1000).toFixed(0);
    var sortanow = rightnow - (rightnow % 600);
    const signature = await signer.signMessage(
      "Signing in to localhost at " + sortanow,
      data[0],
      "test password!"
    );
    const address = data[0];

    return { address, signature };
  } catch (err) {
    setError(err.message);
  }
};
