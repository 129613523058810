import React from "react";
import { View, StyleSheet, TouchableHighlight } from "react-native";
import colors from "../config/colors";
import NtTouchableEffect from "./NtTouchableEffect";

function NtCard({ children, style, containerStyle, onPress = null }) {
  return (
    <View style={[styles.container, style]}>
      <NtTouchableEffect style={containerStyle} onPress={onPress}>
        {children}
      </NtTouchableEffect>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    shadowColor: "black",
    shadowOffset: { width: 0, height: 1 },
    shadowRadius: 2,
    shadowOpacity: 0.26,
    elevation: 1,
    backgroundColor: "white",
    padding: 10,
    borderRadius: 10,
    marginLeft: 4,
    marginRight: 4,
  },
  touchable: {
    borderRadius: 10,
  },
});

export default NtCard;
