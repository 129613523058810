import nftclient, { parseQueryParams } from "./nftclient";
import Logger from "../common/utils/Logger";

export const fetchNfts = (parameters = null) => {
  let query = parseQueryParams(parameters);

  return nftclient.get("/api/nft/" + query);
};

export const fetchNFTForAddress = (address) => {
  return nftclient.get(`/api/nft/${address}/`);
};
