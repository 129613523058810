import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtFooterBar from "../../root/components/NtFooterBar";

class PrivacyController extends React.Component {
  state = {};
  render() {
    return (
      <View style={styles.container}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <View style={{ flex: 1, padding: 20 }}>
            <NtText
              style={{ color: colors.darkest, fontSize: 20, fontWeight: "600" }}
            >
              Privacy
            </NtText>
          </View>
          <NtFooterBar />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightest,
  },
});

export default PrivacyController;
