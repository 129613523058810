//https://reactnavigation.org/docs/configuring-links/#passing-params
//important links: https://reactnavigation.org/blog/2020/05/16/web-support/

import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { View, StyleSheet } from "react-native";
import routes from "./routes";
import MarketingController2 from "../marketing/MarketingController2";
import BrowseController from "../nft/controllers/BrowseController";
import PrivacyController from "../legal/controllers/PrivacyController";
import CookiesController from "../legal/controllers/CookiesController";
import TermsController from "../legal/controllers/TermsController";
import NotFoundController from "../common/controllers/NotFoundController";
import BrowseDetailController from "../nft/controllers/BrowseDetailController";

const Stack = createNativeStackNavigator();

function AuthNavigator({ onControllerChanged, initialRoute }) {
  return (
    <Stack.Navigator
      initialRouteName={initialRoute}
      screenListeners={{
        state: (e) => {
          const controller =
            e.data.state.routes[e.data.state.routes.length - 1].name;
          if (onControllerChanged) {
            onControllerChanged(controller);
          }
        },
      }}
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen
        name={routes.marketing}
        component={MarketingController2}
        options={{ title: "VINZINI,  NFT fueled marketplace" }}
      />

      <Stack.Screen
        name={routes.browse}
        component={BrowseController}
        options={{ title: "VINZINI,  Browse" }}
      />

      <Stack.Screen
        name={routes.browsedetail}
        component={BrowseDetailController}
        options={{ title: "VINZINI,  Detail" }}
      />

      <Stack.Screen
        name={routes.privacy}
        component={PrivacyController}
        options={{ title: "VINZINI,  Privacy" }}
      />

      <Stack.Screen
        name={routes.cookies}
        component={CookiesController}
        options={{ title: "VINZINI,  Cookies" }}
      />

      <Stack.Screen
        name={routes.terms}
        component={TermsController}
        options={{ title: "VINZINI,  Terms" }}
      />

      <Stack.Screen
        name={routes.notFound}
        component={NotFoundController}
        options={{ title: "VINZINI,  No Found" }}
      />
    </Stack.Navigator>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default AuthNavigator;
