import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import colors from "../../config/colors";
import NtFooterBar from "../../root/components/NtFooterBar";
import NtText from "../../components/NtText";
import { fetchNfts } from "../../api/nft";
import Logger from "../../common/utils/Logger";

class BrowseDetailController extends React.Component {
  state = {};

  componentDidMount() {
    const tokenId = decodeURIComponent(this.props.route.params.id);
    this.fetchNFT(tokenId);
  }

  fetchNFT = async (token) => {
    const response = await fetchNfts({ token_id: token });
    Logger("------ here is the response with token: ", response);
  };

  render() {
    return (
      <View style={styles.container}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <View style={{ flex: 1, padding: 20 }}>
            <NtText
              style={{ color: colors.darkest, fontSize: 20, fontWeight: "600" }}
            >
              Detail
            </NtText>
            <NtText
              style={{ color: colors.darkest, fontSize: 14, fontWeight: "500" }}
            >
              Current Item: {decodeURIComponent(this.props.route.params.id)}
            </NtText>
          </View>
          <NtFooterBar />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightest,
  },
});

export default BrowseDetailController;
