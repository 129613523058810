import React from "react";
import { View, StyleSheet, ScrollView, FlatList } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtFooterBar from "../../root/components/NtFooterBar";
import NtCategoryItem from "../components/NtCategoryItem";
import NtNFTItem from "../components/NtNFTItem";
import { fetchCollections } from "../../api/collection";
import Logger from "../../common/utils/Logger";
import { fetchNFTForAddress, fetchNfts } from "../../api/nft";
import BaseController from "../../common/base/BaseController";
import routes from "../../navigation/routes";

class BrowseController extends BaseController {
  state = {
    data: [],
  };

  componentDidMount() {
    this.handleFetchColections();
  }

  handleFetchColections = async () => {
    const response = await fetchNfts();
    if (response.ok) {
      Logger("Available NFTs ", response.data.data);
      this.setState({ data: response.data.data });
    }
  };

  categories = [
    {
      category: "Art",
    },
    {
      category: "Music",
    },
    {
      category: "Trading Cards",
    },
    {
      category: "Collectibles",
    },
    {
      category: "Photography",
    },
    {
      category: "Utility",
    },
  ];

  renderRow = () => {
    return (
      <View
        style={{
          width: 150,
          height: 200,
          backgroundColor: "gray",
          borderRadius: 8,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NtText style={{ color: "white", fontSize: 16, fontWeight: "600" }}>
          Some Demo NFT
        </NtText>
      </View>
    );
  };

  render() {
    return (
      <View style={styles.container}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <View style={{ padding: 20 }}>
            <View>
              <NtText
                style={[
                  styles.sectionTitle,
                  { marginLeft: 5, marginTop: 30, marginBottom: 10 },
                ]}
              >
                Latest
              </NtText>
              <FlatList
                horizontal={true}
                style={{ paddingBottom: 5 }}
                data={this.state.data}
                showsHorizontalScrollIndicator={false}
                ItemSeparatorComponent={() => {
                  return <View style={{ width: 15 }} />;
                }}
                keyExtractor={(item, index) => index.toString()}
                renderItem={({ item }) => (
                  <NtNFTItem
                    item={item}
                    onPress={() => {
                      this.props.navigation.navigate(routes.browsedetail, {
                        id: item.token_id,
                      });
                    }}
                  />
                )}
              />
            </View>

            <View>
              <NtText
                style={[
                  styles.sectionTitle,
                  { marginLeft: 5, marginTop: 30, marginBottom: 10 },
                ]}
              >
                Browse by category
              </NtText>
              <FlatList
                horizontal={true}
                style={{ paddingBottom: 5 }}
                data={this.categories}
                showsHorizontalScrollIndicator={false}
                ItemSeparatorComponent={() => {
                  return <View style={{ width: 15 }} />;
                }}
                keyExtractor={(item, index) =>
                  item.category.toString() + index.toString()
                }
                renderItem={({ item }) => (
                  <NtCategoryItem name={item.category} />
                )}
              />
            </View>
          </View>

          <NtFooterBar />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lightest,
    flex: 1,
  },
  sectionTitle: {
    color: colors.darkest,
    fontSize: 24,
    fontWeight: "600",
  },
});

export default BrowseController;
