import React, { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { linking } from "./src/navigation/routes";
import Toast from "react-native-toast-message";
import {
  asyncStorageGetData,
  asyncStorageSetData,
} from "./src/common/utils/ControllerUtils";
import { UserProvider } from "./src/context/UserContext";
import RootAppController from "./src/root/controllers/RootAppController";
import RootAuthController from "./src/root/controllers/RootAuthController";
import Logger from "./src/common/utils/Logger";
import { setAuthtication } from "./src/api/user";

export const navigationRef = React.createRef();

export default function App() {
  const [user, setUser] = useState();
  const [isReady, setIsReady] = useState(false);

  const loadUser = async () => {
    var user = await asyncStorageGetData("@currentUser3");

    user = JSON.parse(user);
    setAuthtication(user);
    setUser(user);
    setIsReady(true);
    Logger("Current user loaded: ", user);
  };

  const updateUser = (user) => {
    asyncStorageSetData("@currentUser3", user);
    setUser(user);
  };

  const setLayout = (layout) => {
    const updatedUser = { ...user, layout: layout };
    updateUser(updatedUser);
    handleCallback("layout", layout);
  };

  if (!isReady) {
    //will fix this in a bit....
    loadUser();
    return null;
  }

  return (
    <View style={styles.container}>
      <UserProvider
        value={{
          user,
          updateUser,
          setLayout,
        }}
      >
        <NavigationContainer linking={linking} ref={navigationRef}>
          {user && user.authenticated ? (
            <RootAppController />
          ) : (
            <RootAuthController />
          )}
          <Toast />
        </NavigationContainer>
      </UserProvider>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

//deployment
//https://ascendances.wordpress.com/2020/07/03/how-to-self-host-expo-webapp-with-apache-2-4/
//expo publish --release-channel stagin
//expo build:web
